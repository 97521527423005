<template>
  <Layout class="DeliveryManagement">
    <div
      v-loading="statisticsLoading"
      class="overview"
    >
      <div class="items">
        <div class="name">
          待发货的期数
        </div>
        <div class="num">
          {{ statisticsInfo.deliveryPeriod | numberFilter }}  <span class="unit">期</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          已发货的期数
        </div>
        <div class="num">
          {{ statisticsInfo.shippedPeriod | numberFilter }} <span class="unit">期</span>
        </div>
      </div>
    </div>
    <el-form
      ref="form"
      :model="listInfo"
      size="small"
    >
      <el-table
        v-loading="listInfo.loading"
        :data="listInfo.list"
        stripe
        style="width: 100%"
      >
        <el-table-column
          label="期号"
          prop="activityId"
          width="120px"
        />
        <el-table-column
          label="本期商品"
          prop="prizeName"
          width="170px"
        />
        <el-table-column
          label="中奖人姓名"
          prop="winUserName"
          width="120px"
        />
        <el-table-column
          label="中奖人电话"
          prop="winUserPhone"
          width="150px"
        />
        <el-table-column
          label="中奖人地址"
          prop="winUserAddr"
          width="180px"
        />
        <el-table-column
          label="地址确认时间"
          prop="addrConfirmTime"
          width="160px"
        >
          <template v-slot="{row}">
            {{ dataFomat(row.addrConfirmTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="是否已发货"
          prop="isShip"
          width="160px"
        >
          <template v-slot="{row, $index}">
            <template v-if="row.isEditing">
              <el-form-item
                :prop="`list.${$index}.isShip`"
                :rules="[
                  {validator: validatorSend(row) }
                ]"
              >
                <el-switch
                  v-model="row.isShip"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="2"
                />
              </el-form-item>
            </template>
            <template v-else>
              {{ shipMap[row.isShip] || '未知' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="物流信息"
          prop="logisticsInfo"
          width="160px"
        >
          <template v-slot="{row, $index}">
            <template v-if="row.isEditing">
              <el-form-item
                :prop="`list.${$index}.logisticsInfo`"
              >
                <el-input v-model="row.logisticsInfo" />
              </el-form-item>
            </template>
            <template v-else>
              {{ row.logisticsInfo }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="160px"
          fixed="right"
        >
          <template v-slot="{row}">
            <el-button
              :loading="row.loading"
              type="text"
              @click="handleEditData(row)"
            >
              {{ row.isEditing ? '保存': '设置' }}
            </el-button>
            <el-button
              v-if="row.isEditing"
              type="text"
              style="color: gray;"
              @click="handleCancelEdit(row)"
            >
              取消设置
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  getOneDollarShipList,
  updateOneDollarShipStatus,
  oneDollarShipStatistics
} from '@/api/LuckyBuy.js'
import { cloneDeep } from 'lodash'

export default {
  filters: {
    numberFilter (num) {
      if (num === undefined || num === null) {
        return '/'
      }
      return num
    }
  },
  data () {
    return {
      statisticsInfo: {},
      statisticsLoading: false,
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      editingMap: {},
      shipMap: {
        1: '发货',
        2: '未发货'
      }
    }
  },
  created () {
    this.queryDataList()
    this.oneDollarShipStatistics()
  },
  methods: {
    dataFomat (date) {
      if (date === '0000-00-00 00:00:00' || !date) { return '-' }
      return date
    },
    validatorSend (row) {
      return function (rule, value, callback) {
        if (value === 1 && !row.logisticsInfo) {
          callback(new Error('物流信息必填'))
        } else {
          callback()
        }
      }
    },
    oneDollarShipStatistics () {
      this.statisticsLoading = true
      oneDollarShipStatistics({})
        .then(res => {
          if (res.code === 200) {
            this.statisticsInfo = res.data
          }
        })
        .finally(() => {
          this.statisticsLoading = false
        })
    },
    queryDataList () {
      const formData = this.formData
      this.listInfo.loading = true
      getOneDollarShipList(formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleEditData (row) {
      if (row.isEditing) {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$set(row, 'loading', true)
            const { id, activityId, isShip, logisticsInfo } = row
            updateOneDollarShipStatus({
              id,
              activityId,
              isShip,
              logisticsInfo
            }).then(res => {
              if (res.code === 200) {
                this.$message.success('更新成功')
                this.$set(row, 'isEditing', false)
              }
            }).finally(() => {
              this.$set(row, 'loading', false)
            })
          }
        })
      } else {
        this.editingMap[row.id] = cloneDeep(row)
        this.$set(row, 'isEditing', true)
      }
    },
    handleCancelEdit (row) {
      this.$set(row, 'isEditing', false)
      Object.assign(row, this.editingMap[row.id])
    }
  }
}
</script>

<style lang="less" scoped>
.DeliveryManagement {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
}

</style>
