import request from '@/utils/request'

export function getOneDollarPrizeList (data) {
  return request({
    url: '/activity/getOneDollarPrizeList',
    data
  })
}

export function deleteOneDollarPrize (data) {
  return request({
    url: '/activity/deleteOneDollarPrize',
    data
  })
}

export function createOneDollarPrize (data) {
  return request({
    url: '/activity/createOneDollarPrize',
    data
  })
}

export function updateOneDollarPrize (data) {
  return request({
    url: '/activity/updateOneDollarPrize',
    data
  })
}

export function getOneDollarPrizeDetail (data) {
  return request({
    url: '/activity/getOneDollarPrizeDetail',
    data
  })
}

export function createOneDollarActivity (data) {
  return request({
    url: '/activity/createOneDollarActivity',
    data
  })
}

export function getOneDollarActivityList (data) {
  return request({
    url: '/activity/getOneDollarActivityList',
    data
  })
}

export function oneDollarActivityStatistics (data) {
  return request({
    url: '/activity/oneDollarActivityStatistics',
    data
  })
}

export function getOneDollarShipList (data) {
  return request({
    url: '/activity/getOneDollarShipList',
    data
  })
}

export function updateOneDollarShipStatus (data) {
  return request({
    url: '/activity/updateOneDollarShipStatus',
    data
  })
}

export function oneDollarShipStatistics (data) {
  return request({
    url: '/activity/oneDollarShipStatistics',
    data
  })
}

export function getOneDollarAllShare (data) {
  return request({
    url: '/activity/getOneDollarAllShare',
    data
  })
}

export function updateOneDollarWinningShareStatus (data) {
  return request({
    url: '/activity/updateOneDollarWinningShareStatus',
    data
  })
}
